.nav-bar{
    box-shadow: 0px 1px 10px #999;
    background-color: white;
}


.nav-bar-container{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width:600px) {
    .nav-bar{
        justify-content: flex-start;
        align-items: flex-start;
    }
    
}