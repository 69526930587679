.contact-container{
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .form-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px;
    row-gap: 10px;
    margin-bottom: 10px;
    max-width: 80vw;
    width: 600px;
  }