  .center-container{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    align-items: center;
    min-height: 100vh;
  }
  

  .about-me-container{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content:space-around;
    align-items: center;
  }

  #profile-picture{
    width: 200px;
    max-width:90vw;
    border-radius:50%;
  }

  #profile-card{
    width: 500px;
    max-width: 90vw;
    text-align: left;
    font-size: larger;
  }