.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
}

.education-container{
    display: flex;
    align-items: center;
    padding-top: 15px;
    min-height: 100vh;
    justify-content: center;
    flex-direction: column;
}

.education-paper{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px;
    margin-bottom: 10px;
    max-width: 80vw;
    width: 600px;
    overflow: auto;
}

.subheading{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.9rem;
    color: gray;
}