.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
}

.projects-container{
    padding-top:50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 80vh;
    max-width: 80vw;
    width: 600px;
    margin-bottom: 10px;
}

.card{
    width: 400px;
    max-width: 74vw;
}