.action-container{
    display: flex;
    column-gap: 5px;
    row-gap: 5px;
}

@media only screen and (max-width: 369px) {
    .action-container {
        flex-direction: column;
    }
  }